import React from "react";
import Header from "./Header";
import List from "./List";

type Props = {};

export default function Todo(props: Props) {
  return (
    <div>
      <div className="container grey">
        <Header title="TODO App" subtitle="Observability Training" />
      </div>
      <List />
   </div>
  );
}
