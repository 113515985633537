import React from "react";
import Heading from "@paprika/heading";
import "./Header.scss";

type Props = {
  title: string;
  subtitle: string;
};

export default function Header({ title, subtitle }: Props) {
  return (
    <div className="canvas-header">
      <Heading level={1} displayLevel={3} isLight className="canvas-header__robot-title">{title}</Heading>
      <div className="canvas-header__robot-subtitle-container">
        <Heading level={1} displayLevel={3}>
          <span className="canvas-header__robot-subtitle">{subtitle}</span>
        </Heading>
      </div>
    </div>
  );
}
