import React from 'react';
import TodoApiService from 'services/TodoApiService';

export const ApiContext = React.createContext<TodoApiService | undefined>(undefined);

export function useApi() {
  const context = React.useContext(ApiContext);
  if (context === undefined) {
    throw new Error('useApi must be used within an TodoApiProvider');
  }
  return context;
}

export default function TodoApiProvider({ children }: { children: React.ReactNode }) {
  return <ApiContext.Provider value={new TodoApiService()}>{children}</ApiContext.Provider>;
}