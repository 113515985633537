import React from "react";
import Item from "../types/Item";
import Button from "@paprika/button";
import Input from "@paprika/input";
import "./ItemView.scss";

type Props = {
    item: Item,
    editItem:(item) => void;
    saveItem:(item) => void;
    deleteItem:(item) => void;
};



export default function ItemView({item, editItem, saveItem, deleteItem}: Props) {
  const [itemText, setItemText] = React.useState(item.text);
  const [value, setValue] = React.useState(0); // integer state

  const onChange = (event) => {
    const text = event.target.value;
    setItemText(text);
    item.text = text;
  }

  const onEditItem = (item) => {
    editItem(item);
  }

  const onSaveItem = (item) => {
    saveItem(item);
  }

  const onActionItem = () => {
    const edit = item.editable
    item.editable = !edit
    item.text = itemText
    if (edit) {
      onSaveItem(item);
    } else {
      onEditItem(item);
    }
    setValue(value => value + 1); 
  }

  const onDeleteItem = () => {
    deleteItem(item);
  }

  return (
    <div className="item">
      <div className="input">
        <Input isReadOnly={!item.editable} onChange={onChange} placeholder="Input text" size="medium" value={itemText}/>
      </div>
      <div className="buttons">
        <Button kind="secondary" onClick={onActionItem}>{item.editable ? "Save": "Edit"}</Button>
        &nbsp;&nbsp;
        <Button kind="destructive" onClick={onDeleteItem}>
          Delete 
        </Button>
      </div>
    </div>
  );
}
