import React from "react";
import GlobalNavigation, { Logo, CurrentApp, Helper, User } from "sriracha/dist/GlobalNavigation";
import Todo from "./components/Todo";
import "sriracha/dist/GlobalNavigation.scss";
import "./App.scss";
import TodoApiProvider from "providers/TodoApiProvider";

export default function App() {
  return (
    <div className="layout">
      <TodoApiProvider>
      <div className="sriracha">
          <GlobalNavigation>
            <Logo launchpadUrl={"https://www.wegalvanize.com"} />
            <CurrentApp appModuleName="Highbond" appUrl="/" />
            <Helper helpDocUrl={"https://www.wegalvanize.com"} supportUrl={"https://www.wegalvanize.com"} />
            <User username={"Username"} userProfileUrl={"https://www.wegalvanize.com"} logoutUrl={"https://www.wegalvanize.com"} />
          </GlobalNavigation>
      </div>
      <Todo />
      </TodoApiProvider>
    </div>
  );
}
